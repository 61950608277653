@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'General Sans', sans-serif;
    @apply text-base text-Mblack
}

.container {
    max-width: 1300px;
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 92%;
    }
}

section {
    padding: 80px 0;
}


/* start:btn */
.btnClass {
    @apply px-[24px] py-[12px] rounded-full border border-solid leading-6 inline-block
}

.btnClass.large {
    @apply px-[24px] py-[16px]
}

/* end:btn */

.modal-backdrop.show {
    background-color: rgba(23, 23, 23, 0.08) !important;
    backdrop-filter: blur(100px);
}

.fieldInput {
    @apply bg-transparent h-[58px] px-4 rounded-full border border-solid border-[#E5E5E5] outline-none
}

.fieldInput:hover,
.fieldInput:focus,
.fieldInput:active {
    @apply bg-transparent h-[58px] px-4 rounded-full border border-solid border-[#E5E5E5] outline-none
}

.arrowPlus {
    @apply relative p-[0.3rem]
}

.arrowPlus span {
    transition: .5s;
    @apply inline-block w-full h-[1px] bg-Mblack absolute
}

.arrowPlus span:nth-child(1) {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
}

.arrowPlus span:nth-child(2) {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[90deg]
}

.arrowPlus.active span {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[0]
}

.barIcon {
    @apply flex flex-wrap gap-1
}

.barIcon div {
    @apply w-full h-[2px] inline-block bg-Mwhite rounded-sm transition-all duration-500
}
.barIcon div {
    @apply w-full h-[2px] inline-block bg-Mwhite rounded-sm transition-all duration-500
}

.barIcon.active div:nth-child(1) {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 rotate-45
}

.barIcon.active div:nth-child(2) {
    @apply hidden
}

.barIcon.active div:nth-child(3) {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 -rotate-45
}

.menuMobile {
    @apply left-0 -top-[100vh] lg:hidden transition-all duration-500
}

.menuMobile.active {
    @apply top-0
}

.bgDropPopup {
    background: rgba(23, 23, 23, 0.08);
    backdrop-filter: blur(4px);
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dotBold .slick-dots li button::before {
    @apply text-[8px]
}

.dotBold .slick-dots li.slick-active button::before {
    @apply text-Mpurple text-[12px]
}

.checkLarge .form-check-input:checked {
    @apply shadow-none outline-none text-Mpurple bg-Mpurple
}

.checkLarge .form-check-input:focus {
    @apply shadow-none outline-none
}

.checkLarge .form-check-input:active {
    @apply shadow-none outline-none
}

.paginationnCustom li a {
    @apply text-Mblack border-none focus:bg-Mwhite focus:shadow-none rounded-full
}

.paginationnCustom li span {
    @apply rounded-full
}

.wrapVerification .vi__wrapper {
    @apply  w-full
}
.wrapVerification .vi__wrapper .wrap {
    @apply flex items-center justify-between w-full gap-4
}
.wrapVerification .vi__wrapper .wrap .character {
    flex-basis: auto;
    flex-grow: unset;
    @apply w-[56px] h-[56px] rounded-full
}

.wrapVerification .vi__wrapper .wrap .character{
    @apply font-bold text-[24px] text-Mblack
}
.wrapVerification .vi__wrapper .wrap .character:where(.vi__character--inactive){
    @apply bg-[#FAFAFA] border-[#F5F5F5]
}
.wrapVerification .vi__wrapper .wrap .character:where(.vi__character){
    @apply bg-[#FAFAFA] border-[#F5F5F5]
}
.wrapVerification .vi__wrapper .wrap .character:where(.vi__character--selected){
   outline: 2px solid #12A5BC;
   @apply text-Mpurple
}